
























































































































































































































































































































































































































































import {defineComponent, PropType, reactive, toRef} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';

interface TableRow {
  time: number | null;
  absT1: number | null;
  absT2: number | null;
  absT3: number | null;
  absT4: number | null;
}

export default defineComponent({
  name: 'ChemMcM1AA3Lab8Results1',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      options: [
        {
          text: '-3 order',
          value: '-3',
        },
        {
          text: '-2 order',
          value: '-2',
        },
        {
          text: '-1 order',
          value: '-1',
        },
        {
          text: 'Zeroth order',
          value: '0',
        },
        {
          text: 'First order',
          value: '1',
        },
        {
          text: 'Second order',
          value: '2',
        },
        {
          text: 'Third order',
          value: '3',
        },
        {
          text: 'Fourth order',
          value: '4',
        },
        {
          text: 'Fifth order',
          value: '5',
        },
        {
          text: 'Sixth order',
          value: '6',
        },
        {
          text: 'Seventh order',
          value: '7',
        },
        {
          text: 'Eighth order',
          value: '8',
        },
      ],
    };
  },
  setup(props) {
    const inputs = reactive({
      concBr2T1: null,
      concBr2T2: null,
      concBr2T3: null,
      concBr2T4: null,
      concAcetoneT1: null,
      concAcetoneT2: null,
      concAcetoneT3: null,
      concAcetoneT4: null,
      concHClT1: null,
      concHClT2: null,
      concHClT3: null,
      concHClT4: null,
      rows: [
        {time: 45, absT1: null, absT2: null, absT3: null, absT4: null},
        {time: 60, absT1: null, absT2: null, absT3: null, absT4: null},
        {time: 90, absT1: null, absT2: null, absT3: null, absT4: null},
        {time: 120, absT1: null, absT2: null, absT3: null, absT4: null},
        {time: 150, absT1: null, absT2: null, absT3: null, absT4: null},
        {time: 180, absT1: null, absT2: null, absT3: null, absT4: null},
      ] as TableRow[],
      slopeT1: null,
      slopeT2: null,
      slopeT3: null,
      slopeT4: null,
      rateT1: null,
      rateT2: null,
      rateT3: null,
      rateT4: null,
      partDExplanation: null,
      orderBr2: null,
      orderAcetone: null,
      orderHCl: null,
      orderRxn: null,
      partFExplanation: null,
      partGExplanation: null,
      partHExplanation: null,
    });
    const rows = toRef(inputs, 'rows');

    return {
      rows,
      inputs,
    };
  },
  computed: {
    orderA(): string {
      if (this.inputs.orderBr2 == '0') {
        return '';
      } else if (this.inputs.orderBr2 == '1') {
        return '\\ce{[Br2]}';
      } else if (this.inputs.orderBr2 !== null) {
        return '\\ce{[Br2]}^{' + this.inputs.orderBr2 + '}';
      } else {
        return '\\ce{[Br2]}^{a}';
      }
    },
    orderB(): string {
      if (this.inputs.orderAcetone == '0') {
        return '';
      } else if (this.inputs.orderAcetone == '1') {
        return '\\text{[Acetone]}';
      } else if (this.inputs.orderAcetone !== null) {
        return '\\text{[Acetone]}^{' + this.inputs.orderAcetone + '}';
      } else {
        return '\\text{[Acetone]}^{b}';
      }
    },
    orderC(): string {
      if (this.inputs.orderHCl == '0') {
        return '';
      } else if (this.inputs.orderHCl == '1') {
        return '\\ce{[HCl]}';
      } else if (this.inputs.orderHCl !== null) {
        return '\\ce{[HCl]}^{' + this.inputs.orderHCl + '}';
      } else {
        return '\\ce{[HCl]}^{c}';
      }
    },
    rateLaw(): string {
      return '$\\large{\\text{rate}=k\\,' + this.orderA + this.orderB + this.orderC + '}$';
    },
  },
});
